<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="m-2 text-white">Report from <a :href="'/#/customer-profile/' + reportByEntity" class="text-white" v-b-tooltip.hover title="Click to view customer's account">{{reportByEntity}}</a> </h5>
            <p @click="closeModal" class="m-2 x">x</p>
        </div>
      <div class="modalBody" v-if="reportByEntity">
        
        <div v-if="isExpanded" class="market-content">
                <hr>
                <div class="table-responsive" >
                  <h6 class="text-white"></h6>
              <table class="table">
                <thead>
                    <tr >
                        <th>Detail Record Indicator</th>
                        <th>Transaction Date</th>
                        <th>Transaction Code</th>
                        <th>Transaction Reference No.</th>
                        <th>Transaction Amount (Php)</th>
                        <th>Transaction Amount (FX)</th>
                        <th>FX Currency Code</th>
                        <th>Nature/Purpose of Transaction/Virtual Currency Name/Code</th>
                        <th>Effectivity Date</th>
                        <th>Maturity Date /Expiry Date</th>
                        <th>Amount of Claim/Dividend</th>
                        <th>No. of shares/units</th>
                        <th>Net Asset Value</th>
                        <th>Name of Correspondent Bank</th>
                        <th>Address</th>
                        <th>Country Code of Correspondent Bank</th>
                    </tr>

                    
                    </thead>
                    <tbody v-for="report in reports" :key="report.id">
                        <tr >
                        
                        <td>{{ report.recordIndicator }}</td>
                        <td>{{ formatDate(report.txnDate) }}</td>
                        <td>{{ report.txnRefNo }}</td>
                        <td>{{ report.accountNo }}</td>
                        <td>{{ report.txnAmount }}</td>
                        <td>{{ report.fxTxnAmount }}</td>
                        <td>{{ report.fxCurrencyCode }}</td>
                        <td>{{ report.purposeOfTxn }}</td>
                        <td>{{ formatDate(report.effectivityDate) }}</td>
                        <td>{{ formatDate(report.maturityDate) }}</td>
                        <td>{{ report.claimAmount }}</td>
                        <td>{{ report.noOfShares }}</td>
                        <td>{{ report.netAssetValue }}</td>
                        <td>{{ report.correspondentBank }}</td>
                        <td>{{ report.correspondentBankAddress }}</td>
                        <td>{{ report.correspondentBankCountry }}</td>
                    
                    </tr>
                    </tbody>
                </table>
              </div>
          </div>
           
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
    components: {

    },
    props:["reportByEntity"],
    data: function() {
        return {
          reports:[],
          entityId:'',
          isExpanded: false,
       

            
        }
    },

   

    methods: {
      formatDate: function(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
        },
        closeModal(){
            this.$emit("close")
        },

        getReportHistory(){
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/ax/aml/report/history/all',
                }
                this.axios(options)
                .then((response) => {
                this.reportHistory = response.data
               
                }).catch((err) => {
                })
      },

      getReportPerEntity(){
     
            const params = {
            entityId: this.reportByEntity
            }
            // const data = Object.keys(params)
            //             .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            //             .join('&');
          const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                params,
                url: '/ax/aml/report/history/',
                }
                this.axios(options)
                .then((response) => {
                  this.reports = response.data
                this.isExpanded = true
               
                }).catch((err) => {
                })
      },
          checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    // console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  

					})
			},
        
      
     
    },
    beforeMount(){
        this.checkSession();
    },
    mounted(){
        this.getReportHistory();
        this.getReportPerEntity();
    }
}
</script>

<style scoped lang="scss">
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 90%;
    height: auto;
    margin: 10px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
    /* z-index: 2; */
    max-height: calc(100vh - 110px);
    overflow-y: auto; 
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:black;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>