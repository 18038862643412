<template>
  <div class="main">
       <TheTopbar/>
    <slot />
    <div>
    <div class="main-wrapper">
      <div class="content-wrapper">
        <div class="modal-header text-right">
              <h5 class="text-white">AML Report</h5>
        </div>
        <div class="">
                <hr>
                <div class="market-content">
              <table >
                <thead>
                    <tr >
                        <th>Report Date</th>
                        <th>Institution Code</th>
                        <th>Reference ID</th>
                        <th>Report Type</th>
                        <th>Format Code</th>
                        <th>Supervising Agency</th>
                        <th>Submission Type</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                       <tr v-if="reportHistory.length == 0">
                        <td colspan="12" >No reports found</td>
                       </tr>
                        <tr v-else v-for="report in reportHistory" :key="report.id">
                        <td>{{ formatDate(report.reportDate) }}</td>
                        <td>{{ report.institutionCode }}</td>
                        <td>{{ report.amlTaggingId }}</td>
                        <td >{{ report.reportType }}</td>
                        <td v-if="report.formatCode == '1'">CTR</td>
                        <td v-if="report.formatCode == 'A'">Covered Person</td>
                        <td v-if="report.formatCode == 'B'">PPP</td>
                        <td v-if="report.formatCode == 'C'">KYC Docs Requested by AMLC</td>
                        <td v-if="report.formatCode == 'D'">Shared Aml Studies</td>
                        <td v-if="report.formatCode == 'F'">Freeze Order</td>
                        <td v-if="report.agency == '1'">BSP</td>
                        <td v-if="report.agency == '2'">SEC</td>
                        <td v-if="report.agency == '3'">AMLC</td>
                        <td v-if="report.agency == '4'">DNFBP</td>
                        <td v-if="report.submissionType == 'A'">Add</td>
                        <td v-if="report.submissionType == 'E'">Edit</td>
                        <td v-if="report.submissionType == 'D'">Delete</td>
                       
                        <td>
                          <!-- v-if="report.fxTxnAmount > 0" -->
                          <button class="btn btn-light" @click="openReportPerEntity(report.addedBy)" v-b-tooltip.hover title="Click to view customer's AML reporting">Expand</button>
                          <!-- <button class="btn btn-secondary" @click="cancel" v-if="isExpanded">Collapse</button> -->
                        </td>
                    </tr>
                    </tbody>
                </table>
              </div>
                
    
              </div>


             
              
  
      <aml-report-modal v-if="isExpanded" @close="openReportPerEntity()" :reportByEntity="reportByEntity"></aml-report-modal>
           
         </div>
        </div>
        </div>
    </div>
</template>

<script>
import TheTopbar from '../components/TheTopbar.vue'
import moment from 'moment';
import AmlReportModal from '../components/modal/AmlReportModal.vue';

export default {
    components: {
      TheTopbar,
        AmlReportModal
    },
    data: function() {
        return {
          reportHistory:[],
          entityId:'',
          step:'1',
          reportByEntity:[],
          isExpanded: false,
          reportByEntity:''
             
           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
       updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
        formatDate: function(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
        },
        openReportPerEntity(data){
          this.reportByEntity = data
          this.isExpanded = !this.isExpanded

        },
        getReportHistory(){
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/ax/aml/report/history/all',
                }
                this.axios(options)
                .then((response) => {
                this.reportHistory = response.data
               
                }).catch((err) => {
                })
      },
      getReportPerEntity(entityId){
           this.entityId = entityId
            const params = {
            entityId: entityId
            }
            // const data = Object.keys(params)
            //             .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            //             .join('&');
          const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'vmiadmin-authz': localStorage.getItem("tokenId") 
                }, 
                params,
                url: '/ax/aml/report/history/',
                }
                this.axios(options)
                .then((response) => {
                  this.reportByEntity = response.data
                this.isExpanded = true
               
                }).catch((err) => {
                })
      },
    
      cancel(){
        this.isExpanded = false
      },
        closeModal(){
        this.$emit("close")
        window.location.reload();
        },
        isNumber: function(evt) {
		        evt = (evt) ? evt : window.event;
		        var charCode = (evt.which) ? evt.which : evt.keyCode;
		        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
		          evt.preventDefault();
		        } else {
		          return true;
		        }
		      },

       
      
    },
  
    mounted(){
        this.getReportHistory();
        
    }
}
</script>

<style scoped lang=scss>
.content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }

.modal-title{
    margin: 0 auto;
    color:white;
}
.table{
  padding: 10px;
    border-collapse: collapse;
    width: 100% !important;
    margin: 0 10px;
    background: O rgba(255,255,255,0.2);
    backdrop-filter:blur(20px);
    box-shadow:0 0 30px rgba(0,0,0,0.3);
   border: 2px solid white;
    color: white;
    text-align: center !important;
    font-size: 12px;
    border-radius: 10px;
    padding: 0.5em 2em;
    overflow-y: scroll;
    max-height: 700px; 
    z-index: 9999 !important;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.userPic{
    width: 10rem;
}
.image{
  width: 100%;
}
.img img{
    width: 100%;
    border-radius: 10px;;
     box-shadow: 0 2px 2px rgba(50,50,50,.50);
     border:7px solid #fff;
     border-radius: 10px;;
     background-color: white;
      background-size:cover;
      object-fit: contain;
  }

  .info{
    width: 100%;
    margin-left: 5rem;
    padding-left:1rem;
      .info .name{
      padding: 0.5rem 0 2rem 0;
        .info .name h3{
        font-size: 1rem;
        line-height:110%;
        font-weight: 400;
          .info .name p{
          font-size:0.9rem;
          color: black;
          }
        }
      }
    .info .desc .soc-med{
      display: flex !important;
      flex-direction: row;
      justify-content: space-evenly;
      font-size: 15px !important;
    }
  }
.profile-card {
  height: auto !important;
  width: 100%;
  margin: auto 10px;
  background: Orgba(255,255,255,0.2);
  box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
  border: 4px white solid;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
    &:hover{
    animation-name: bounce;
    animation-timing-function: ease;

    }

}
 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #726d75;
        width: 100%;
    }
   


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>